"use strict"; // Start of use strict
// 1. revolution slider
function revolutionSliderActiver() {
    if (jQuery('.rev_slider_wrapper #slider1').length) {
        jQuery("#slider1").revolution({
            sliderType: "standard",
            sliderLayout: "auto",
            dottedOverlay: "yes",
            delay: 5000,
            navigation: {
                arrows: {
                    enable: true,
                    left: {
                        h_align: "left",
                        v_align: "center",
                        h_offset: 60,
                        v_offset: 0
                    },
                    right: {
                        h_align: "right",
                        v_align: "center",
                        h_offset: 60,
                        v_offset: 0
                    }

                }
            },

            gridwidth: [1170, 940, 720, 480],
            gridheight: [700, 600, 550, 500],
            lazyType: "none",
            parallax: {
                type: "mouse",
                origo: "slidercenter",
                speed: 2000,
                levels: [2, 3, 4, 5, 6, 7, 12, 16, 10, 50],
            },
            shadow: 0,
            spinner: "off",
            stopLoop: "off",
            stopAfterLoops: -1,
            stopAtSlide: -1,
            shuffle: "off",
            autoHeight: "off",
            hideThumbsOnMobile: "off",
            hideSliderAtLimit: 0,
            hideCaptionAtLimit: 0,
            hideAllCaptionAtLilmit: 0,
            debugMode: false,
            fallbacks: {
                simplifyAll: "off",
                nextSlideOnWindowFocus: "off",
                disableFocusListener: false,
            }
        });
    }
    ;
}

//====Main menu===
function mainmenu() {

    var navcollapse = jQuery('.main-menu .navigation li');
    navcollapse.hover(function () {
        jQuery(this).children('ul').stop(true, false, true).slideToggle(300);
    });
    //Submenu Dropdown Toggle
    if (jQuery('.main-menu .mobile-menu li.dropdown ul').length) {
        jQuery('.main-menu .mobile-menu li.dropdown').append('<div class="dropdown-btn"></div>');

        //Dropdown Button
        jQuery('.main-menu .mobile-menu li.dropdown .dropdown-btn').click(function () {
            jQuery(this).prev('ul').slideToggle(500);
        });
    }

}

//===Header Sticky===
function stickyHeader() {
    if (jQuery('.stricky').length) {
        var strickyScrollPos = 100;
        if (jQuery(window).scrollTop() > strickyScrollPos) {
            jQuery('.stricky').addClass('stricky-fixed');
            jQuery('.scroll-to-top').fadeIn(1500);
        } else if (jQuery(this).scrollTop() <= strickyScrollPos) {
            jQuery('.stricky').removeClass('stricky-fixed');
            jQuery('.scroll-to-top').fadeOut(1500);
        }
    }
    ;
}

function stickyHeader() {
    if (jQuery('.stricky').length) {
        var strickyScrollPos = 100;
        if (jQuery(window).scrollTop() > strickyScrollPos) {
            jQuery('.stricky').removeClass('fadeIn animated');
            jQuery('.stricky').addClass('stricky-fixed fadeInDown animated');
            jQuery('.scroll-to-top').fadeIn(500);
        }
        else if (jQuery(this).scrollTop() <= strickyScrollPos) {
            jQuery('.stricky').removeClass('stricky-fixed fadeInDown animated');
            jQuery('.stricky').addClass('slideIn animated');
            jQuery('.scroll-to-top').fadeOut(500);
        }
    }
    ;
}

// progressBarConfig
function progressBarConfig() {
    var progressBar = jQuery('.progress');
    if (progressBar.length) {
        progressBar.each(function () {
            var Self = jQuery(this);
            Self.appear(function () {
                var progressValue = Self.data('value');

                Self.find('.progress-bar').animate({
                    width: progressValue + '%'
                }, 100);

                Self.find('span.value').countTo({
                    from: 0,
                    to: progressValue,
                    speed: 100
                });
            });
        })
    }

}


// 3. gallery fancybox activator
function GalleryFancyboxActivator() {
    var galleryFcb = jQuery('.fancybox');
    if (galleryFcb.length) {
        galleryFcb.fancybox({
            openEffect: 'elastic',
            closeEffect: 'elastic',
            helpers: {
                media: {}
            }
        });
    }
}

// 4. select menu
function selectMenu() {
    if (jQuery('.select-menu').length) {
        jQuery('.select-menu').selectmenu();
    }
    ;
}

//  team carousel
function teamCarousel() {
    if (jQuery('.team-carousel').length) {
        jQuery('.team-carousel').owlCarousel({
            loop: true,
            margin: 32,
            nav: true,
            dots: false,
            autoplay: true,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1,
                    loop: true
                },
                480: {
                    items: 2,
                    loop: true
                },
                768: {
                    items: 3,
                    loop: true
                },
                1000: {
                    items: 4,
                    loop: true
                }
            }
        });
    }
    ;
}

//  Instagram carousel
function instagramCarousel() {
    if (jQuery('.Instagram-carousel').length) {
        jQuery('.Instagram-carousel').owlCarousel({
            loop: true,
            margin: 32,
            nav: true,
            dots: false,
            autoplay: true,
            autoplayHoverPause: true,
            navText: [
                '<i class="fa fa-angle-left"></i>',
                '<i class="fa fa-angle-right"></i>'

            ],
            responsive: {
                0: {
                    items: 2,
                    loop: true
                },
                480: {
                    items: 2,
                    loop: true
                },
                768: {
                    items: 4,
                    loop: true
                },
                1000: {
                    items: 6,
                    loop: true
                }
            }
        });
    }
    ;
}

//  about carousel
function aboutCarousel() {
    if (jQuery('.about-carousel').length) {
        jQuery('.about-carousel').owlCarousel({
            loop: true,
            margin: 32,
            nav: true,
            dots: false,
            autoplay: true,
            smartSpeed: 2000,
            autoplayHoverPause: true,
            navText: [
                '<i class="fa fa-angle-left"></i>',
                '<i class="fa fa-angle-right"></i>'

            ],
            responsive: {
                0: {
                    items: 1,
                    loop: true
                },
                480: {
                    items: 1,
                    loop: true
                },
                768: {
                    items: 1,
                    loop: true
                },
                1000: {
                    items: 1,
                    loop: true
                }
            }
        });
    }
    ;
}

function gallerycarousel() {
    if (jQuery('.gallery_carousel').length) {
        jQuery('.gallery_carousel').owlCarousel({
            loop: true,
            margin: 0,
            nav: false,
            dots: false,
            autoWidth: false,
            autoplay: true,
            autoplayTimeout: 3000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1,
                    autoWidth: false
                },
                380: {
                    items: 2,
                    autoWidth: false
                },
                540: {
                    items: 3,
                    autoWidth: false
                },
                740: {
                    items: 4,
                    autoWidth: false
                },
                1000: {
                    items: 5,
                    autoWidth: false
                }
            }
        });
    }
    ;
}


function galleryslide() {
    if (jQuery('.gallery_slide').length) {
        jQuery('.gallery_slide').owlCarousel({
            loop: true,
            margin: 0,
            items: 1,
            nav: false,
            dots: true,
            autoWidth: false,
            autoplay: true,
            autoplayTimeout: 3000,
            autoplayHoverPause: true
        });
    }
    ;
}


function testimonialsarousel() {
    if (jQuery('.testimonial-carousel').length) {
        jQuery('.testimonial-carousel').owlCarousel({
            loop: true,
            margin: 0,
            items: 1,
            nav: true,
            navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
            dots: true,
            autoWidth: false,
            autoplay: true,
            autoplayTimeout: 3000,
            autoplayHoverPause: true
        });
    }
    ;
}

function testimonialsarousel2() {
    if (jQuery('.testimonial-carousel2').length) {
        jQuery('.testimonial-carousel2').owlCarousel({
            loop: true,
            margin: 0,
            items: 1,
            nav: true,
            navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
            dots: true,
            autoWidth: false,
            autoplay: true,
            autoplayTimeout: 3000,
            autoplayHoverPause: true
        });
    }
    ;
}

function feedbackcarousel() {
    if (jQuery('.feedback-carousel').length) {
        jQuery('.feedback-carousel').owlCarousel({
            loop: true,
            margin: 0,
            items: 1,
            nav: false,
            dots: true,
            autoWidth: false,
            autoplay: true,
            autoplayTimeout: 3000,
            autoplayHoverPause: true
        });
    }
    ;
}

//  founder carousel
function founderCarousel() {
    if (jQuery('.founder-carousel').length) {
        jQuery('.founder-carousel').owlCarousel({
            loop: true,
            items: true,
            nav: false,
            navText: false,
            autoplay: true,
            smartSpeed: 2000,
            dots: true,
            autoWidth: false,

            responsive: {
                0: {
                    items: 1,
                    loop: true
                },
                480: {
                    items: 1,
                    loop: true
                },
                768: {
                    items: 1,
                    loop: true
                },
                1000: {
                    items: 1,
                    loop: true
                }
            }
        });
    }
    ;
}

//  feature Carousel
function featureCarousel() {
    if (jQuery('.feature-two-carousel').length) {
        jQuery('.feature-two-carousel').owlCarousel({
            loop: true,
            items: true,
            nav: false,
            navText: false,
            autoplay: true,
            smartSpeed: 4000,
            autoplayTimeout: 100,
            dots: true,
            autoWidth: false,

            responsive: {
                0: {
                    items: 1,
                    loop: true
                },
                480: {
                    items: 2,
                    loop: true
                },
                768: {
                    items: 3,
                    loop: true
                },
                1000: {
                    items: 4,
                    loop: true
                }
            }
        });
    }
    ;
}

//  team carousel
function projectcarousel() {
    if (jQuery('.project-carousel').length) {
        jQuery('.project-carousel').owlCarousel({
            loop: true,
            margin: 32,
            nav: true,
            dots: false,
            autoplay: true,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1,
                    loop: true
                },
                480: {
                    items: 1,
                    loop: true
                },
                768: {
                    items: 2,
                    loop: true
                },
                1000: {
                    items: 3,
                    loop: true
                }
            }
        });
    }
    ;
}

// Fact Counter
function factCounter() {
    if (jQuery('.fact-counter').length) {
        jQuery('.fact-counter .counter-column.animated').each(function () {

            var $t = jQuery(this),
                n = $t.find(".count-text").attr("data-stop"),
                r = parseInt($t.find(".count-text").attr("data-speed"), 10);

            if (!$t.hasClass("counted")) {
                $t.addClass("counted");
                jQuery({
                    countNum: $t.find(".count-text").text()
                }).animate({
                    countNum: n
                }, {
                    duration: r,
                    easing: "linear",
                    step: function () {
                        $t.find(".count-text").text(Math.floor(this.countNum));
                    },
                    complete: function () {
                        $t.find(".count-text").text(this.countNum);
                    }
                });
            }

        });
    }
}


// 9. gallery
function fleetGallery() {
    if (jQuery('.mixit-gallery').length) {
        jQuery('.mixit-gallery').mixItUp();
    }
    ;
}

//LightBox / Fancybox
if (jQuery('.lightbox-image').length) {
    jQuery('.lightbox-image').fancybox({
        openEffect: 'fade',
        closeEffect: 'fade',
        helpers: {
            media: {}
        }
    });
}

// 10. typed plugin
function typed() {
    if (jQuery(".typed").length) {
        jQuery(".typed").typed({
            stringsElement: jQuery('.typed-strings'),
            typeSpeed: 200,
            backDelay: 1500,
            loop: true,
            contentType: 'html', // or text
            // defaults to false for infinite loop
            loopCount: false,
            callback: function () {
                null;
            },
            resetCallback: function () {
                newTyped();
            }
        });
    }
    ;
}

// 14. single featured hover
function singleFeaturedHover() {
    if (jQuery('.single-our-feature').length) {
        jQuery('.single-our-feature').hover(function () {
            var bgHover = jQuery(this).data('hover-background');
            jQuery(this).css({
                'background-image': 'url(' + bgHover + ')'
            });
        }, function () {
            jQuery(this).css({
                'background-image': ''
            });
        });
    }
    ;
}


// 20. Responsive Video
function respnsiveVideo() {
    if (jQuery('.responsive-video-box').length) {
        jQuery('.responsive-video-box').fitVids();
    }
}


//21 Price Ranger
function priceFilter() {
    if (jQuery('.price-ranger').length) {
        jQuery('.price-ranger #slider-range').slider({
            range: true,
            min: 10,
            max: 200,
            values: [11, 99],
            slide: function (event, ui) {
                jQuery('.price-ranger .ranger-min-max-block .min').val('$' + ui.values[0]);
                jQuery('.price-ranger .ranger-min-max-block .max').val('$' + ui.values[1]);
            }
        });
        jQuery('.price-ranger .ranger-min-max-block .min').val('$' + jQuery('.price-ranger #slider-range').slider('values', 0));
        jQuery('.price-ranger .ranger-min-max-block .max').val('$' + jQuery('.price-ranger #slider-range').slider('values', 1));
    }
    ;
}


// 22. Cart Touch Spin
function cartTouchSpin() {
    if (jQuery('.quantity-spinner').length) {
        jQuery("input.quantity-spinner").TouchSpin({
            verticalbuttons: true
        });
    }
}


// 23. Video Fancybox
function videoFancybox() {
    if (jQuery("a.video-fancybox").length) {
        jQuery("a.video-fancybox").click(function () {
            $.fancybox({
                'padding': 0,
                'autoScale': false,
                'transitionIn': 'none',
                'transitionOut': 'none',
                'title': this.title,
                'width': 680,
                'height': 495,
                'href': this.href.replace(new RegExp("watch\\?v=", "i"), 'v/'),
                'type': 'swf',
                openEffect: 'elastic',
                closeEffect: 'elastic',
                helpers: {
                    media: {}
                },
                'swf': {
                    'wmode': 'transparent',
                    'allowfullscreen': 'true'
                }
            });

            return false;
        });
    }
    ;
}


// 27. Select menu
function selectDropdown() {
    if (jQuery(".selectmenu").length) {
        jQuery(".selectmenu").selectmenu();

        var changeSelectMenu = function (event, item) {
            jQuery(this).trigger('change', item);
        };
        jQuery(".selectmenu").selectmenu({change: changeSelectMenu});
    }
    ;
}


// 31. Tabs Box
function tabbox() {
    if (jQuery('.tabs-box').length) {

        //Tabs
        jQuery('.tabs-box .tab-buttons .tab-btn').on('click', function (e) {

            e.preventDefault();
            var target = jQuery(jQuery(this).attr('data-tab'));

            target.parents('.tabs-box').find('.tab-buttons').find('.tab-btn').removeClass('active-btn');
            jQuery(this).addClass('active-btn');
            target.parents('.tabs-box').find('.tabs-content').find('.tab').fadeOut(0);
            target.parents('.tabs-box').find('.tabs-content').find('.tab').removeClass('active-tab');
            jQuery(target).fadeIn(300);
            jQuery(target).addClass('active-tab');
        });

    }

}

// 32. imgbxslider
function imgbxslider() {
    if (jQuery('.img-slide-box').length) {
        jQuery('.img-slide-box').bxSlider({
            adaptiveHeight: true,
            auto: true,
            controls: false,
            maxSlides: 1,
            minSlides: 1,
            moveSlides: 1,
            pause: 5000,
            speed: 700
        });
    }
}

// Prealoder
function handlePreloader() {
    if (jQuery('.preloader').length) {
        jQuery('.preloader').delay(200).fadeOut(500);
    }
}

// Scroll to top
function scrollToTop() {
    if (jQuery('.scroll-top').length) {

        //Check to see if the window is top if not then display button
        jQuery(window).scroll(function () {
            if (jQuery(this).scrollTop() > 200) {
                jQuery('.scroll-top').fadeIn();
            } else {
                jQuery('.scroll-top').fadeOut();
            }
        });

        //Click event to scroll to top
        jQuery('.scroll-top').click(function () {
            jQuery('html, body').animate({scrollTop: 0}, 1500);
            return false;
        });
    }
}

function singleProduct() {

    jQuery('.flexslider').flexslider({
        animation: "slide",
        controlNav: "thumbnails"
    });


}

// Main Menu Function
function themeMenu() {
    if (jQuery("#main_menu").length) {
        jQuery("#main_menu").menuzord({
            animation: "zoom-out"
        });
    }
}

function thmLightBox() {
    if (jQuery('.img-popup').length) {
        var groups = {};
        jQuery('.img-popup').each(function () {
            var id = parseInt(jQuery(this).attr('data-group'), 10);

            if (!groups[id]) {
                groups[id] = [];
            }

            groups[id].push(this);
        });


        $.each(groups, function () {

            jQuery(this).magnificPopup({
                type: 'image',
                closeOnContentClick: true,
                closeBtnInside: false,
                gallery: {enabled: true}
            });

        });

    }
    ;
}

function galleryMasonaryLayout() {
    if (jQuery('.masonary-layout').length) {
        jQuery('.masonary-layout').isotope({
            layoutMode: 'masonry'
        });
    }

    if (jQuery('.post-filter').length) {
        jQuery('.post-filter li').children('span').click(function () {
            var Self = jQuery(this);
            var selector = Self.parent().attr('data-filter');
            jQuery('.post-filter li').children('span').parent().removeClass('active');
            Self.parent().addClass('active');


            jQuery('.filter-layout').isotope({
                filter: selector,
                animationOptions: {
                    duration: 500,
                    easing: 'linear',
                    queue: false
                }
            });
            return false;
        });
    }

    if (jQuery('.post-filter.has-dynamic-filter-counter').length) {
        // var allItem = jQuery('.single-filter-item').length;

        var activeFilterItem = jQuery('.post-filter.has-dynamic-filter-counter').find('li');

        activeFilterItem.each(function () {
            var filterElement = jQuery(this).data('filter');
            console.log(filterElement);
            var count = jQuery('.gallery-content').find(filterElement).length;

            jQuery(this).children('span').append('<span class="count"><b>' + count + '</b></span>');
        });
    }
    ;
}


//Contact Form Validation
if (jQuery('#contact-form').length) {
    jQuery("#contact-form").validate({
        submitHandler: function (form) {
            var form_btn = jQuery(form).find('button[type="submit"]');
            var form_result_div = '#form-result';
            jQuery(form_result_div).remove();
            form_btn.before('<div id="form-result" class="alert alert-success" role="alert" style="display: none;"></div>');
            var form_btn_old_msg = form_btn.html();
            form_btn.html(form_btn.prop('disabled', true).data("loading-text"));
            jQuery(form).ajaxSubmit({
                dataType: 'json',
                success: function (data) {
                    if (data.status == 'true') {
                        jQuery(form).find('.form-control').val('');
                    }
                    form_btn.prop('disabled', false).html(form_btn_old_msg);
                    jQuery(form_result_div).html(data.message).fadeIn('slow');
                    setTimeout(function () {
                        jQuery(form_result_div).fadeOut('slow')
                    }, 6000);
                }
            });
        }
    });
}

//Contact Form Validation
if (jQuery('#footer-form').length) {
    jQuery("#footer-form").validate({
        submitHandler: function (form) {
            var form_btn = jQuery(form).find('button[type="submit"]');
            var form_result_div = '#form-result';
            jQuery(form_result_div).remove();
            form_btn.before('<div id="form-result" class="alert alert-success" role="alert" style="display: none;"></div>');
            var form_btn_old_msg = form_btn.html();
            form_btn.html(form_btn.prop('disabled', true).data("loading-text"));
            jQuery(form).ajaxSubmit({
                dataType: 'json',
                success: function (data) {
                    if (data.status == 'true') {
                        jQuery(form).find('.form-control').val('');
                    }
                    form_btn.prop('disabled', false).html(form_btn_old_msg);
                    jQuery(form_result_div).html(data.message).fadeIn('slow');
                    setTimeout(function () {
                        jQuery(form_result_div).fadeOut('slow')
                    }, 6000);
                }
            });
        }
    });
}


if (jQuery('.accordion-box').length) {
    jQuery('.accordion-box .acc-btn').click(function () {
        if (jQuery(this).hasClass('active') !== true) {
            jQuery('.accordion-box .acc-btn').removeClass('active');
        }

        if (jQuery(this).next('.acc-content').is(':visible')) {
            jQuery(this).removeClass('active');
            jQuery(this).next('.acc-content').slideUp(500);
        } else {
            jQuery(this).addClass('active');
            jQuery('.accordion-box .acc-content').slideUp(500);
            jQuery(this).next('.acc-content').slideDown(500);
        }
    });
}


// Elements Animation
if (jQuery('.wow').length) {
    var wow = new WOW(
        {
            boxClass: 'wow',      // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            offset: 0,          // distance to the element when triggering the animation (default is 0)
            mobile: true,       // trigger animations on mobile devices (default is true)
            live: true       // act on asynchronously loaded content (default is true)
        }
    );
    wow.init();
}


// instance of fuction while Document ready event
jQuery(document).ready(function () {
    (function ($) {
        jQuery(".current-menu-item").addClass('current');
        revolutionSliderActiver();
        mainmenu();
        selectMenu();
        fleetGallery();
        GalleryFancyboxActivator();
        typed();
        singleFeaturedHover();
        respnsiveVideo();
        priceFilter();
        cartTouchSpin();
        videoFancybox();
        selectDropdown();
        tabbox();
        imgbxslider();
        handlePreloader();
        scrollToTop();
        singleProduct();
        themeMenu();
        thmLightBox();
        galleryMasonaryLayout();
        progressBarConfig();

    })(jQuery);
});

// instance of fuction while Window Load event
jQuery(window).load(function () {
    (function ($) {
        gallerycarousel();
        galleryslide();
        testimonialsarousel();
        testimonialsarousel2();
        feedbackcarousel();
        projectcarousel();
        teamCarousel();
        aboutCarousel();
        instagramCarousel();
        founderCarousel();
        featureCarousel();
    })(jQuery);
});

// instance of fuction while Window Scroll event
jQuery(window).scroll(function () {
    (function ($) {
        stickyHeader();
        factCounter();
    })(jQuery);
});
